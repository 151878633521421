import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private toastController: ToastController) { }

  public toast(message: string, type = 'success', duration = 2000, buttons = []) {
    let color = 'light',
      cssClass = 'pa-toast pa-toast--top',
      position: "top" | "bottom" | "middle" = 'top';

    switch (type) {
      case 'success':
        color = 'success',
        cssClass = 'pa-toast pa-toast--top pa-toast--success',
        position = 'top',
        buttons = buttons
        break;

      case 'danger':
        color = 'danger',
        cssClass = 'pa-toast pa-toast--top pa-toast--error',
        position = 'top',
        buttons = buttons
        break;

      default:
        color = 'light',
        cssClass = 'pa-toast pa-toast--top',
        position = 'top',
        buttons = buttons
    }

    this.toastController.create({
      message: message,
      duration: duration,
      color: color,
      position: position,
      cssClass: cssClass,
      buttons: buttons
    }).then(toast => {
      toast.present();
    });
  }

  public clear() {
    this.toastController.dismiss();
  }
}