import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { EmployeeService } from './employee.service';
import { ActivityService } from './activity.service';
import { TimetrackService } from './timetrack.service';
import { orderBy } from 'lodash-es';

export interface Employee {
  cod_badge: number,
  matricola: string,
  nome: string,
  cognome: string
}

export interface Activity {
  id: number,
  code: string,
  name: string,
  color: string,
  parent?: any,
  children: number
}

export interface Timetrack {
  id: number,
  date: string,
  worked_minutes: number,
  activity?: Activity,
  cod_badge?: number,
  activity_id?: number,
  created_at?: any,
  updated_at?: any,
  code?: string,
  name?: string,
  color?: string,
  accountable?: number,
  active?: number,
  parent_id?: any,
  total_minutes?: number

}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private employeeData: Employee;
  private activitiesData: Activity[];
  private activitiesPage = 1;
  private activitiesPageSize = 5;
  private activitiesHasMore = false;
  private selectedActivity = null;
  private lastActivitiesData: any[];
  private statusGlobalData = null;
  private timetrackDateData: string = null;
  
  public employee = new BehaviorSubject({
    cod_badge: null,
    matricola: null,
    nome: null,
    cognome: null
  });
  
  public statusGlobal = new BehaviorSubject({
    date: null,
    status: null,
    percentage: null,
    completed: null,
    checkable: null
  });

  public activities = new BehaviorSubject([]);
  public hasMoreactivities = new BehaviorSubject(false);
  public lastActivities = new BehaviorSubject([]);
  public timetracks = new BehaviorSubject([]);

  constructor(
    private employeeService: EmployeeService,
    private activityService: ActivityService,
    private timetrackService: TimetrackService,
  ) { }

  async waitFor(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async asyncForEach(source, callback) {
    if (source instanceof Array) {
      for (let index = 0; index < source.length; index++) {
        await callback(source[index], index, source);
      }
    }
    else {
      for(var key in source) {
        if (source.hasOwnProperty(key)) {
          await callback(source[key], key, source);
        }
      }
    }
  }

  public fetchSampleData() {
    const sampleObservable = of(['one', 'two', 'three']);
    return sampleObservable;
  }

  public loadEmployee() {
    return new Promise(async resolve => {
      this.employeeService.getEmployee().then((res: Employee) =>{
        this.employeeData = res;
        this.employee.next(res);
        resolve(true);
      });
    })
  }

  public loadActivities(parentId: number) {
    let filters = [];
    filters.push(
      {
        name: 'parent',
        value: parentId
      }
    );
    if (parentId == 0) {
      filters.push(
        {
          name: 'first_level',
          value: true
        }
      );
    }
    return new Promise(async resolve => {
      this.activityService.getPagedActivities(this.activitiesPage, this.activitiesPageSize, filters).then((res: any) =>{
        let activities: Activity[] = [];
        if (res.meta.page.total > this.activitiesPageSize) {
          console.log('res', res);
          this.activitiesHasMore = true;
          this.hasMoreactivities.next(true);
        }
        else {
          this.activitiesHasMore = false;
          this.hasMoreactivities.next(false);
        }
        res.data.forEach(element => {
          let activity: Activity = {
            id: 0,
            code: 'string',
            name: 'string',
            color: 'string',
            parent: null,
            children: 0
          };
          activity.id = Number(element.id);
          activity.code = String(element.attributes.code);
          activity.name = String(element.attributes.name);
          activity.color = String(element.attributes.color);
          activity.children = Number(element.attributes.children);
          activity.parent = element.attributes.parent;
          activities.push(activity);
        });
        this.activitiesData = activities;
        this.activities.next(activities);
        resolve(true);
      });
    })
  }

  public loadAllActivities(parentId: number) {
    let filters = [];
    filters.push(
      {
        name: 'parent',
        value: parentId
      }
    );
    if (parentId == 0) {
      filters.push(
        {
          name: 'first_level',
          value: true
        }
      );
    }
    return new Promise(async resolve => {
      this.activityService.getAllActivities(filters).then((res: any) =>{
        let activities: Activity[] = [];
        res.data.forEach(element => {
          let activity: Activity = {
            id: 0,
            code: 'string',
            name: 'string',
            color: 'string',
            parent: null,
            children: 0
          };
          activity.id = Number(element.id);
          activity.code = String(element.attributes.code);
          activity.name = String(element.attributes.name);
          activity.color = String(element.attributes.color);
          activity.children = Number(element.attributes.children);
          activity.parent = element.attributes.parent;
          activities.push(activity);
        });
        this.activitiesData = activities;
        this.activities.next(activities);
        resolve(true);
      });
    })
  }

  public loadLastUsedActivities(limit = 5) {
    return new Promise(async resolve => {
      this.activityService.getLastActivities(limit).then((res: any) =>{
        let lastActivities: any[] = [];
        for (let key of Object.keys(res)) {
          const value: any[] = res[key];
          let activities: Activity[] = [];
          const date: any[] = value;
          date.forEach(element => {
            let activity: Activity = {
              id: 0,
              code: 'string',
              name: 'string',
              color: 'string',
              parent: null,
              children: 0
            };
            activity.id = Number(element.id);
            activity.code = String(element.attributes.code);
            activity.name = String(element.attributes.name);
            activity.color = String(element.attributes.color);
            activity.children = Number(element.attributes.children);
            activity.parent = element.attributes.parent;
            activities.push(activity);
          });
          let dateActivities: any = {
            date: key,
            activities: activities
          };
          lastActivities.push(dateActivities);
        };
        const sortedLastActivities = orderBy(lastActivities, ['date'], ['desc']);
        this.lastActivitiesData = sortedLastActivities;
        this.lastActivities.next(sortedLastActivities);
        resolve(true);
      });
    })
  }

  public setSelectedActivity(activity: any) {
    this.selectedActivity = activity;
  }

  public getSelectedActivity() {
    return this.selectedActivity;
  }

  public addTimeTrack(activityId: number, date: any, minutes: number) {
    return new Promise(async resolve => {
      const timeTrackData = {
        data: {
          type: 'timetracks',
          attributes: {
            activity_id: activityId,
            date: date,
            worked_minutes: minutes
          }
        }
      };
      this.timetrackService.addTimetrack(timeTrackData).then((res: any) => {
        console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public getTimeTrack(id: number) {
    return new Promise(async resolve => {
      this.timetrackService.getTimetrack(id).then((res: any) => {
        console.log(res);
        let timetrack: Timetrack = {
          id: 0,
          date: '',
          worked_minutes: 0,
          activity: null
        };
        timetrack.id = Number(res.data.id);
        timetrack.date = String(res.data.attributes.date);
        timetrack.worked_minutes = Number(res.data.attributes.worked_minutes);
        // TODO deepclone
        timetrack.activity = res.data.attributes.activity;

        resolve(timetrack);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public getTimeTrackPerDay(from: string, to: string) {
    return new Promise(async resolve => {
      this.timetrackService.getTimetrackPerDay(from, to).then((res: any) => {
        // console.log(res);
        let timetracks: any[] = [];
        res.data.forEach(element => {
          let timetrack: Timetrack = {
            id: 0,
            date: '1970-01-01',
            worked_minutes: 0,
            cod_badge: 1000001,
            activity_id: 0,
            activity: null,
            created_at: null,
            updated_at: null,
            code: '',
            name: '',
            color: '',
            accountable: 1,
            active: 1,
            parent_id: null,
            total_minutes: 0
          };
          timetrack.id = Number(element.attributes.id);
          timetrack.date = String(element.attributes.date);
          timetrack.worked_minutes = Number(element.attributes.worked_minutes);
          timetrack.cod_badge = Number(element.attributes.cod_badge);
          timetrack.activity_id = Number(element.attributes.activity_id);
          timetrack.activity = (element.attributes.activity);
          // timetrack.code = String(element.attributes.code);
          // timetrack.name = String(element.attributes.name);
          // timetrack.color = String(element.attributes.color);
          timetrack.total_minutes = Number(element.attributes.total_minutes);
          timetracks.push(timetrack);
        });

        this.timetracks.next(timetracks);
        resolve(timetracks);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public editTimeTrack(id: number, date: any, minutes: number) {
    return new Promise(async resolve => {
      const timeTrackData = {
        data: {
          type: 'timetracks',
          id: String(id),
          attributes: {
            // activity_id: activityId,
            date: date,
            worked_minutes: minutes
          }
        }
      };
      this.timetrackService.updateTimetrack(id, timeTrackData).then((res: any) => {
        console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public setTimeTrackDate(timeTrackDate: string) {
    return new Promise(async resolve => {
      this.timetrackDateData = timeTrackDate;
      resolve(true);
    })
  }

  public getTimeTrackDate() {
    return new Promise<string>(async resolve => {
      resolve(this.timetrackDateData);
    })
  }

  public deleteTimeTrack(id: number) {
    return new Promise(async resolve => {
      this.timetrackService.deleteTimetrack(id).then((res: any) => {
        console.log(res);
        resolve(true);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public loadStatusGlobal() {
    return new Promise(async resolve => {
      this.employeeService.getStatusGlobal().then((res: any) =>{
        let statusGlobal = {
          date: '1970-01-01',
          status: false,
          percentage: 0,
          checkable: false,
          completed: false
        };

        statusGlobal.date = String(res.attributes.date);
        statusGlobal.status = Boolean(res.attributes.status);
        statusGlobal.checkable = Boolean(res.attributes.checkable);
        statusGlobal.completed = Boolean(res.attributes.completed);
        statusGlobal.percentage = Number(res.attributes.percentage);
        this.statusGlobalData = statusGlobal;
        this.statusGlobal.next(statusGlobal);
        resolve(true);
      });
    })
  }

  public getStatusGlobal() {
    return new Promise(async resolve => {
      let statusGlobal = {
        date: (this.statusGlobalData && this.statusGlobalData.date) ? this.statusGlobalData.date : '1970-01-01',
        status: (this.statusGlobalData && this.statusGlobalData.status) ? this.statusGlobalData.status : false,
        percentage: (this.statusGlobalData && this.statusGlobalData.percentage) ? this.statusGlobalData.percentage : 0,
        checkable: (this.statusGlobalData && this.statusGlobalData.checkable) ? this.statusGlobalData.checkable : false,
        completed: (this.statusGlobalData && this.statusGlobalData.completed) ? this.statusGlobalData.completed : false
      };
      resolve(statusGlobal);
    })
  }

  public getStatusPerDay(from: string, to: string) {
    return new Promise(async resolve => {
      this.employeeService.getStatusPerDay(from, to).then((res: any) =>{
        // console.log('res', res);
        let days: any[] = [];
        for (let key of Object.keys(res)) {
          const element: any = res[key];
          let day: any = {
            id: 0,
            date: '1970-01-01',
            status: false,
            checkable: false,
            completed: false,
            reported: 0,
            worked: 0,
            difference: 0,
            percentage: 0
          };
          day.id = Number(element.id);
          day.date = String(element.attributes.date);
          day.status = Boolean(element.attributes.status);
          day.checkable = Boolean(element.attributes.checkable);
          // TODO
          // day.checkable = true;
          day.completed = Boolean(element.attributes.completed);
          day.reported = Number(element.attributes.reported);
          day.worked = Number(element.attributes.worked);
          day.difference = Number(element.attributes.difference);
          day.percentage = Number(element.attributes.percentage);
          days.push(day);
        };
        // console.log('days', days);
        resolve(days);
      });
    })
  }
}
