import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

export interface Filter {
  name: string;
  value: any;
}

@Injectable()
export class JsonApiService {

  API_BASE: string = environment.base_url + '/' + environment.version;

  constructor(
    private injector: Injector,
    private authService: AuthService,
    // tslint:disable-next-line: no-shadowed-variable
  ) {}

  public getCollectionPaginated(resource: string, page: number = 1, pageSize: number = 20, filters?: Array<Filter>, orderby?: string) {
    const ServiceHeaders = this.authService.getJsonApiHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    }
    payload = payload.append('page[number]', page.toString());
    payload = payload.append('page[size]', pageSize.toString());

    const options = { headers: ServiceHeaders, params: payload };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${resource}`, options);
  }

  public customGetRequest($route) {
    const ServiceHeaders = this.authService.getJsonApiHeaders();
    const payload = new HttpParams();
    const options = { headers: ServiceHeaders, params: payload };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${$route}`, options);
  }

  public resetPassword(emailAddress: string) {
    const uri: string = environment.base_url + '/auth/reset-password-request';
    const payload =
    {
      email: emailAddress
    };
    return this.injector.get(HttpClient).post(uri, payload);
  }

  public getCollection(resource: string, filters?: Array<Filter>, orderby?: string) {
    const ServiceHeaders = this.authService.getJsonApiHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    }
    const options = { headers: ServiceHeaders, params: payload };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${resource}`, options);
  }

  public getEntity(resource: string, id) {
    const ServiceHeaders = this.authService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${resource}/${id}`, options);
  }

  public updateEntity(resource: string, id, data) {
    const ServiceHeaders = this.authService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    return this.injector.get(HttpClient).patch(`${this.API_BASE}/${resource}/${id}`, data, options);
  }

  public postEntity(resource: string, data, addictionalPath?: string) {
    const ServiceHeaders = this.authService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    let uri = '';
    if (addictionalPath) {
      uri = `${this.API_BASE}/${resource}/${addictionalPath}`;
    } else {
      uri = `${this.API_BASE}/${resource}`;
    }
    return this.injector.get(HttpClient).post(uri, data, options);
  }

  public postEntityCustomPath(data, path?: string) {
    const ServiceHeaders = this.authService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    const uri = `${this.API_BASE}/${path}`;
    return this.injector.get(HttpClient).post(uri, data, options);
  }

  public deleteEntity(resource: string, id) {
    const ServiceHeaders = this.authService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    const uri = `${this.API_BASE}/${resource}/${id}`;
    return this.injector.get(HttpClient).delete(uri, options);
  }
}
