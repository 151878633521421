import { Injectable } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { JsonApiService } from './jsonapi.service';

@Injectable()
export class TimetrackService {

  constructor(
    private errorHandler: ErrorHandlerService,
    public api: JsonApiService
  ) { }

  async getTimetrack(id: number) {
    return new Promise((resolve, reject) => {
      try {
        this.api.getEntity('timetracks', id).subscribe(
          (res: any) => {
            const timetrack = res;
            resolve(timetrack);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );

      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }
  
  async getTimetrackPerDay(from: string, to: string) {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('timetracks/per-day?from=' + from + '&to=' + to).subscribe(
          (res: any) => {
            const timetracks = res;
            resolve(timetracks);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async addTimetrack(data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.postEntity('timetracks', data).subscribe(
          (res: any) => {
            const timetrack = res;
            resolve(timetrack);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async updateTimetrack(id: number, data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.updateEntity('timetracks', id, data).subscribe(
          (res: any) => {
            const timetrack = res;
            resolve(timetrack);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async deleteTimetrack(id: number) {
    return new Promise((resolve, reject) => {
      try {
        this.api.deleteEntity('timetracks', id).subscribe(
          (res: any) => {
            resolve(true);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }
}
