import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private currentLoader = null;
  private counter = 0;

  constructor(
    public loadingController: LoadingController,
    private translate: TranslateService
  ) {
  }

  public async createLoader() {
    // const message = await this.translate.get('COMMON.wait').toPromise();
    const message = '';
    this.currentLoader = await this.loadingController.create({
      cssClass: 'pa-loader',
      message: 'Attendi...',
      duration: 2000,
      spinner: 'crescent'
    });
  }

  public async start() {
    // console.log('start');
    if (this.counter === 0 && this.currentLoader) {
      // await this.createLoader();
      this.currentLoader.present();
    }
    this.counter++;
  }

  public async stop() {
    // console.log('stop');
    if (this.counter > 0) {
      this.counter--;
    }
    if (!this.counter && this.currentLoader) {
      // console.log('stop2 - ', this.counter);
      // console.log('dismiss', this.counter, this.currentLoader);
      this.currentLoader.dismiss();
    }
    else {
      // console.log('else', this.counter, this.currentLoader);
    }
  }
}
