import { Injectable } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { JsonApiService } from './jsonapi.service';

@Injectable()
export class EmployeeService {

  constructor(
    private errorHandler: ErrorHandlerService,
    public api: JsonApiService
  ) { }

  async getEmployee() {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('user').subscribe(
          (res: any) => {
            const employee = res;
            resolve(employee);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }
  
  async getStatusGlobal() {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('user/global-status').subscribe(
          (res: any) => {
            const status = res;
            resolve(status);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }
  
  async getStatusPerDay(from: string, to: string) {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('user/status-per-day?from=' + from + '&to=' + to).subscribe(
          (res: any) => {
            const days = res;
            resolve(days);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }
}
