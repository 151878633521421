import { Injectable } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { Filter, JsonApiService } from './jsonapi.service';

@Injectable()
export class ActivityService {

  constructor(
    private errorHandler: ErrorHandlerService,
    public api: JsonApiService
  ) { }

  async getActivity() {
    console.warn('TODO');
    // TODO
    // const coll: any = await this.api.getCollection('favorites').toPromise();
    // const employee: any = await this.api.customGetRequest('user');
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('user').subscribe(
          (res: any) => {
            const employee = res;
            resolve(employee);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getAllActivities(filters?: Array<Filter>, orderby?: string) {
    return new Promise((resolve, reject) => {
      try {
        this.api.getCollection('activities', filters, orderby).subscribe(
          (res: any) => {
            const activities = res;
            resolve(activities);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getPagedActivities(page: number, pageSize: number, filters?: Array<Filter>, orderby?: string) {
    return new Promise((resolve, reject) => {
      try {
        this.api.getCollectionPaginated('activities', page, pageSize, filters, orderby).subscribe(
          (res: any) => {
            const activities = res;
            resolve(activities);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getLastActivities(limit: number) {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('activities/last-used?limit=' + limit).subscribe(
          (res: any) => {
            const days = res;
            resolve(days);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }
}
