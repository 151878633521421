import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: './pages/login/login.module#LoginPageModule',
    canActivate: [LoginGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule), 
    canActivate: [AuthGuard]
  },
  {
    path: 'record-activity',
    loadChildren: () => import('./pages/record-activity/record-activity.module').then(m => m.RecordActivityPageModule), 
    canActivate: [AuthGuard]
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/calendar/calendar.module').then(m => m.CalendarPageModule), 
    canActivate: [AuthGuard]
  },
  {
    path: 'activities',
    loadChildren: () => import('./pages/activities/activities.module').then( m => m.ActivitiesPageModule), 
    canActivate: [AuthGuard]
  },
  {
    path: 'activity',
    loadChildren: () => import('./pages/activity/activity.module').then( m => m.ActivityPageModule), 
    canActivate: [AuthGuard]
  },
  {
    path: 'timetrack-added/:timetrack_id',
    loadChildren: () => import('./pages/timetrack-added/timetrack-added.module').then( m => m.TimetrackAddedPageModule), 
    canActivate: [AuthGuard]
  },
  {
    path: 'time-picker',
    loadChildren: () => import('./modals/time-picker/time-picker.module').then( m => m.TimePickerPageModule)
  },
  {
    path: 'date-picker',
    loadChildren: () => import('./modals/date-picker/date-picker.module').then( m => m.DatePickerPageModule)
  },

  {
    path: "home",
    loadChildren: () =>
      import("./statics/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: 'buttons',
    loadChildren: () => import('./statics/buttons/buttons.module').then( m => m.ButtonsPageModule)
  },
  {
    path: 'form-elements',
    loadChildren: () => import('./statics/form-elements/form-elements.module').then( m => m.FormElementsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./statics/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home-dashboard',
    loadChildren: () => import('./statics/home-dashboard/home-dashboard.module').then( m => m.HomeDashboardPageModule)
  },
  {
    path: 'dashboard-components',
    loadChildren: () => import('./statics/dashboard-components/dashboard-components.module').then( m => m.DashboardComponentsPageModule)
  },
  {
    path: 'lists',
    loadChildren: () => import('./statics/lists/lists.module').then( m => m.ListsPageModule)
  },
  {
    path: 'tipography',
    loadChildren: () => import('./statics/tipography/tipography.module').then( m => m.TipographyPageModule)
  },
  {
    path: 'list-search',
    loadChildren: () => import('./statics/list-search/list-search.module').then( m => m.ListSearchPageModule)
  },
  {
    path: 'utilities',
    loadChildren: () => import('./statics/utilities/utilities.module').then( m => m.UtilitiesPageModule)
  },
  {
    path: 'modal',
    loadChildren: () => import('./statics/modal/modal.module').then( m => m.ModalPageModule)
  },
  {
    path: 'inserimento-giorno-ora',
    loadChildren: () => import('./statics/inserimento-giorno-ora/inserimento-giorno-ora.module').then( m => m.InserimentoGiornoOraPageModule)
  },
  {
    path: 'empty-state-list',
    loadChildren: () => import('./statics/empty-state-list/empty-state-list.module').then( m => m.EmptyStateListPageModule)
  },
  {
    path: 'registrazione',
    loadChildren: () => import('./statics/registrazione/registrazione.module').then( m => m.RegistrazionePageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./statics/messages/messages.module').then( m => m.MessagesPageModule)
  },
  {
    path: 'modal-full',
    loadChildren: () => import('./statics/modal-full/modal-full.module').then( m => m.ModalFullPageModule)
  },
  {
    path: 'registrazione-error',
    loadChildren: () => import('./statics/registrazione-error/registrazione-error.module').then( m => m.RegistrazioneErrorPageModule)
  },
  {
    path: 'ricerca-attivita-empry',
    loadChildren: () => import('./statics/ricerca-attivita-empry/ricerca-attivita-empry.module').then( m => m.RicercaAttivitaEmpryPageModule)
  },
  {
    path: 'skeleton-list',
    loadChildren: () => import('./statics/skeleton-list/skeleton-list.module').then( m => m.SkeletonListPageModule)
  },
  {
    path: 'modal-edit-activity',
    loadChildren: () => import('./statics/modal-edit-activity/modal-edit-activity.module').then( m => m.ModalEditActivityPageModule)
  },
  {
    path: 'day-activities',
    loadChildren: () => import('./statics/day-activities/day-activities.module').then( m => m.DayActivitiesPageModule)
  },
  {
    path: 'modal-stick-bottom',
    loadChildren: () => import('./statics/modal-stick-bottom/modal-stick-bottom.module').then( m => m.ModalStickBottomPageModule)
  },
  {
    path: 'modal-stick-bottom-m',
    loadChildren: () => import('./statics/modal-stick-bottom-m/modal-stick-bottom-m.module').then( m => m.ModalStickBottomMPageModule)
  },
  {
    path: 'picker',
    loadChildren: () => import('./statics/picker/picker.module').then( m => m.PickerPageModule)
  },
  {
    path: 'calendario',
    loadChildren: () => import('./statics/calendario/calendario.module').then( m => m.CalendarioPageModule)
  },
  {
    path: 'calendario-timetrack',
    loadChildren: () => import('./statics/calendario-timetrack/calendario-timetrack.module').then( m => m.CalendarioTimetrackPageModule)
  },
  {
    path: 'modal-stick-bottom-xl',
    loadChildren: () => import('./statics/modal-stick-bottom-xl/modal-stick-bottom-xl.module').then( m => m.ModalStickBottomXlPageModule)
  },
  {
    path: 'confirm',
    loadChildren: () => import('./modals/confirm/confirm.module').then( m => m.ConfirmPageModule)
  },
  {
    path: 'day-activities',
    loadChildren: () => import('./modals/day-activities/day-activities.module').then( m => m.DayActivitiesPageModule)
  },
  {
    path: 'icons',
    loadChildren: () => import('./statics/icons/icons.module').then( m => m.IconsPageModule)
  },
  {
    path: 'timetrack-edit',
    loadChildren: () => import('./modals/timetrack-edit/timetrack-edit.module').then( m => m.TimetrackEditPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
