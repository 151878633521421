// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // auth_base_url: "http://palagina.test/api",
  // base_url: "http://palagina.test/api",
  // domain: "http://palagina.test",
  auth_base_url: "https://staging-be.palagina.netseven.it/api",
  base_url: "https://staging-be.palagina.netseven.it/api",
  domain: "https://staging-be.palagina.netseven.it",
  version: "v1",
  app_version: "1.0.104s",
  default_minutes: 240,
  time_step: 10,
  time_min: 10,
  time_max: 530,
  idle_time: 30,
  idle_timeout: 5,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
