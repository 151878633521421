import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notify.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorResponse } from '../models/jsonapi.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  API_AUTH_BASE: string = environment.auth_base_url;
  API_BASE: string = environment.base_url;
  API_VERSION: string = environment.version;

  private translations = [];
  private prefix = 'palagina_';
  private access_token = '';
  private refresh_token = '';
  private auth = '';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private notification: NotificationService,
    public httpClient: HttpClient,
    // public Md5: Md5,
    // public storage: Storage,
  ) {
    this.translate.get([
      'i18n.auth_error.0',
      'i18n.auth_error.401',
      'i18n.auth_error.404',
      'i18n.auth_error.412',
      'i18n.auth_error.422',
      'i18n.auth_error.500',
      'i18n.auth_error.505',
      'i18n.auth_error.default'
    ]).subscribe(t => { this.translations = t; });
  }

  public getJsonApiHeaders(): HttpHeaders {
    const header = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      Authorization: 'Bearer ' + this.getToken()
    });
    return header;
  }

  userAuthenticated() {
    if(this.auth != '')
      return true;
    else
      return false;
  }

  loginWithCredentials(usr,pwd) {
    // return new Promise(resolve => {
    //   resolve(true);
    // })
  }

  loginWithCode(code: string) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const options = { headers: headers };
      this.httpClient.post(`${this.API_AUTH_BASE}/${this.API_VERSION}/login`, {
        'badge': code
      }, options)
      .subscribe(
        (data: any) => {
          // console.log('LOGIN', data);
          resolve(true);
          this.auth = 'auth';
          this.access_token = data.access_token;
          // TODO Prevedere il salvataggio sullo store se richiesta la persistenza.
        },
        (error: any) => {
          // console.error('LOGIN', error); resolve(false);
          this.handlerError(error);
          reject(true);
        }
      );
    });
  }

  refreshToken() {}
  
  autoLogin(){
    return new Promise(resolve => {
      resolve(false);
    });
  }

  logout(){
    return new Promise(resolve => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.access_token
      });
      const options = { headers: headers };
      this.httpClient.get(`${environment.auth_base_url}/${this.API_VERSION}/logout`, options)
      .subscribe(
        (data: any) => {
          // console.log('LOGOUT', data);
          this.access_token = '';
          this.auth = '';
          resolve(true);
        },
        (error: any) => {
          // console.error('LOGOUT', error); resolve(false);
          this.access_token = '';
          this.auth = '';
          this.handlerError(error);
        }
      );
      // this.storage.remove(this.prefix+'access_token');
      // this.storage.remove(this.prefix+'refresh_token');
      // this.storage.remove(this.prefix+'user_id');
    });
  }

  sendToken(token: string) {
    this.access_token = token;
  }

  getToken() {
    return this.access_token;
  }

  public handlerError(error: ErrorResponse) {
    // console.log(error);
    switch (error.status) {
      case 0:
        this.notification.toast(this.translations['i18n.auth_error.0'], 'danger');
        break;
        
      case 401:
        this.notification.toast(this.translations['i18n.auth_error.401'], 'danger');
        this.access_token = '';
        this.auth = '';
        // redirect
        const route = '';
        this.router.navigate([route], {});
        break;

      case 404:
        this.notification.toast(this.translations['i18n.auth_error.404'], 'danger');
        break;

      case 412: // eventualmente diversificare qui.
        this.notification.toast(this.translations['i18n.auth_error.412'], 'danger');
        break;

      case 422: // eventualmente diversificare qui.
        this.notification.toast(this.translations['i18n.auth_error.422'], 'danger');
        break;
        
      case 500:
        this.notification.toast(this.translations['i18n.auth_error.500'], 'danger');
        break;

      case 505:
        this.notification.toast(this.translations['i18n.auth_error.505'], 'danger');
        break;

      default:
        this.notification.toast(error.status.toString() + ' - ' + this.translations['i18n.auth_error.default'], 'danger');
        break;
    }
  }
}
